<template>
  <div>
    <p>创建时间：{{ mydata.ctime }}</p>
    <p></p>
    <p></p>
    <p></p>

    <el-upload
      action="http://127.0.0.1:5000/users/avatar_upload"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :data="updata"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
import { accountinfo } from "@/apis/users";
import { getYYYYMMDD, bus } from "@/utils/utils";

export default {
  data() {
    return {
      mydata: {}, //个人信息

      //         {
      // account: "admin"
      // ctime: "2020-05-19T09:00:58.000Z"
      // id: 55
      // imgUrl: "http://127.0.0.1:5000/upload/imgs/acc_img/h1301451230.jpg"
      // userGroup: "超级管理员"}

      imageUrl: "", //上传中间的图片
      updata: { id: localStorage.id }, //上传时携带的额外参数
    };
  },
  created() {
    accountinfo({ id: localStorage.id }).then((res) => {
      console.log(res.data.accountInfo.ctime);

      //时间转换
      res.data.accountInfo.ctime = getYYYYMMDD(res.data.accountInfo.ctime);
      this.mydata = res.data.accountInfo;
    });
  },
  methods: {
    handleAvatarSuccess(res, file) {
      // res: 上传成功的响应
      // file: 指向刚才上传的本地图片
      console.log(res, file);
      this.imageUrl = URL.createObjectURL(file.raw); //图片回显
      //   location.reload(); //F5刷新 可以实现，但很野！因为会连带整个页面一起刷新

      // 上传成功后，通知Index，让它刷新一下头像！！！！
      
      // 使用bus发出一个传值通知
      bus.$emit('refreshHead', this.imageUrl);
    },
  },
};
</script>

<style lang="less" scoped>
</style>